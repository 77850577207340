<template>
  <div>
    <f-page-header>
      <h1>
        {{ learningPathTitle }}
      </h1>
      <p class="white--text">{{ learingPathDescription }}</p>
      <div v-if="isAdmin" class="d-flex align-center top-actions">
        <div class="top-actions__item">
          <v-chip :color="statusColor">{{ statusLabel }}</v-chip>
        </div>
        <a @click="editLearningPath" v-if="isAdmin">
          <v-icon>mdi-pencil</v-icon>
          {{ $t("labels.edit") }}
        </a>
      </div>
      <template v-slot:breadcrumbs>
        <a @click="back">
          <v-icon>mdi-arrow-left</v-icon>
          {{ $t("labels.backToLearningPaths") }}
        </a>
      </template>
    </f-page-header>
    <f-container topBottom>
      <f-reports-view :learningPathReport="this.id"></f-reports-view>
    </f-container>
  </div>
</template>

<script>
import ReportsViewLearningPaths from "../../../components/Reports/ReportsViewLearningPaths.vue";
import { UserType } from "../../../helpers/user_utils";

export default {
  components: {
    "f-reports-view": ReportsViewLearningPaths
  },
  data: () => ({
    id: null,
    list: [],
    learningPath: {
      title: [],
      description: []
    }
  }),
  created() {
    this.id = this.$route.params.id;
    this.get();
  },
  methods: {
    async get() {
      var result = await this.$store.dispatch("learningPaths/get", this.id);
      this.learningPath = result;
    },
    editLearningPath() {
      this.$router.push({
        name: "learning-paths-edit",
        params: this.learningPath._id
      });
    },
    back() {
      this.$router.push({
        name: "learning-paths-list"
      });
    }
  },
  computed: {
    isAdmin() {
      return this.$store.state.auth.user.type == UserType.Admin;
    },
    learningPathTitle() {
      return this.learningPath.title[this.currentLanguage];
    },
    languages() {
      return this.learningPath && this.learningPath.langs
        ? this.learningPath.langs
        : [];
    },
    currentLanguage() {
      return this.languages.includes(this.$i18n.locale)
        ? this.$i18n.locale
        : this.languages[0];
    },
    learingPathDescription() {
      return this.learningPath.description[this.currentLanguage];
    },
    statusLabel() {
      return this.$i18n.t(`labels.status.${this.learningPath.status}`);
    },
    statusColor() {
      return this.learningPath.status === "published" ? "green" : "gray";
    }
  }
};
</script>

<style lang="scss">
.dots-color {
  color: #ffc107 !important;
  margin-top: 20px;
  margin-bottom: 25px;
}

.top-actions {
  margin: 10px 0 0 0;
  &__item {
    margin: 0 10px 0 0;
  }
}

.white--text {
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 300;
}
</style>
